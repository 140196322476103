import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Suspense, useState, useEffect } from 'react';
import { Button, Form, Row, Col, Container } from 'react-bootstrap';
import { Await, useAsyncValue, useNavigate } from 'react-router-dom';
import { getAllCourses } from '../../util/courseutil';
import Loading from '../UtilComponents/Loading';
import SelectCourseHierarchy from '../UtilComponents/SelectCourseHierarchy';
import { makePath } from '../../util/generalutil';
import PracticeExercises from './PracticeExercises';

export function SetExercises(props) {
    const courseList = useAsyncValue().data;
    function validate() {
        return (courseId != null) && (courseId != "");
    }
    function handleSubmit(e) {
        e.preventDefault();
        if (validate())
            props.onsubmit(courseId, chapterId, topicId);
        setShowExercises(true);
    }

    let [courseId, setCourseId] = useState(null);
    let [chapterId, setChapterId] = useState(null);
    let [topicId, setTopicId] = useState(null);
    let [showExercises, setShowExercises] = useState(false);

    return (
        <div>

            <Form onSubmit={handleSubmit} className="card p-3 gap-3">
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <SelectCourseHierarchy
                                courseList={courseList}

                                courseId={courseId}
                                setCourseId={setCourseId}
                                chapterId={chapterId}
                                setChapterId={setChapterId}
                                topicId={topicId}
                                setTopicId={setTopicId}
                            />
                        </Col>
                        {/* <Col md={2}>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={!validate()}>
                                START
                                <FontAwesomeIcon
                                    className='ms-2 me-2'
                                    icon={faAngleRight} />
                            </Button>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col md={12}>
                            {courseId && 
                                <PracticeExercises 
                                    courseId={courseId}
                                    chapterId={chapterId}
                                    topicId={topicId}
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </Form>

        </div>
    )
}

function SelectExercises() {
    let navigate = useNavigate();
    function navigateExercises(course_id, chapter_id, topic_id) {


    }

    return (
        <div>
            <Suspense fallback={<Loading show={"loading exercise courses"} />}>
                <Await resolve={getAllCourses().catch(console.log)}>

                    <SetExercises onsubmit={navigateExercises} />

                </Await>
            </Suspense>
        </div>
    )
}

export default SelectExercises;