
import React, { useEffect } from 'react'
import '../../css/PracticeFAQ.css';
import config from '../../../src/json/config.json'
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useState } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";

const CommunityMember = () => {
  const [data, setData] = useState([]);

  const [studentdata, setStudentdata] = useState([]);

  const { communityID } = useParams();
  useEffect(() => {

    community(communityID);

    community1();

  }, [communityID]); // Re-run when `id` changes

  const community = (communityID) => {
    const url = `${config.backEnd.hostURL}/admin/community/member/${communityID}`; // Dynamic URL

    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        setData((response.data.data));
      })
      .catch((err) => {
        console.error("Error fetching community data:", err);
        setData([]); // Prevents breaking the .map function
      });
  };

  const community1 = () => {
    const url = `${config.backEnd.hostURL}/admin/student/member`;
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        setStudentdata((response.data.data));
      })

      .catch((err) => {
        console.error("Error fetching community data:", err);
        setStudentdata([]); // Prevents breaking the .map function
      });
  };


  const addCommunity = (communityID, id) => {
    const url = config.backEnd.hostURL + "/admin/community/member/" + communityID;
    const data = { memberIds: [id] }; // Corrected key

    axios
      .post(url, data, { headers: { "Content-Type": "application/json" } })
      .then((response) => {
        console.log("Saved successfully:", response.data);
        community(communityID); // Refresh the list
      })
      .catch((error) => {
        console.error("Error saving community:", error);
      });
  };



  let navigate = useNavigate();
  const handleback = () => {
    navigate(-1)
  }
  return (
    <>
      <div className='communitybutton'>
        <Button variant="primary" onClick={handleback} >Go Back</Button>
      </div>
      <h1 className="title">Community Member</h1>
      {/* 
      <div className="table-container">
        <table className="user-table">
          <thead>
            <tr>

              <th>Name</th>
              <th>User Name</th>
              <th>Email</th>
              <th>Role</th>

            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={item.id}>

                <td>{item.name}</td>
                {item.members && item.members.length > 0 ? (
                  item.members.map((member) => (
                    <>
                      <td>{member.username}</td>
                      <td>{member.email}</td>
                      <td>{member.role}</td>
                    </>
                  ))
                ) : (
                  <>
                    <td colSpan="2">No Members</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>

        </table>
      </div> */}
      <div className="table-container">
        <table className="user-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Username</th>
              <th>Email</th>
              <th>Role</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <React.Fragment key={index}>
                {/* First Row: Community/Group Name */}


                {/* Separate Rows for Each Member */}
                {item.members &&
                  item.members.map((member, memberIndex) => (
                    <tr key={`${index}-${memberIndex}`}>
                      <td>{item.name}</td> {/* Empty cell to align under 'Name' column */}
                      <td>{member.username}</td>
                      <td>{member.email}</td>
                      <td>{member.role}</td>
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>


      <h1 className="title">List of students </h1>
      <div className="table1-container1">
        <table className="user1-table1">
          <thead>
            <tr>

              <th>User Name</th>
              <th>Email</th>
              <th>Password</th>
              <th>Mobile No</th>
              <th>Role</th>
              <th>Actions </th>


            </tr>
          </thead>
          <tbody>
            {studentdata.map((item) => (
              <tr key={item.id}>
                <td>{item.username}</td>
                <td>{item.email}</td>
                <td>{item.password}</td>
                <td>{item.role}</td>
                <td>{item.mobileNo}</td>
                <td>
                  <Button onClick={() => addCommunity(communityID, item._id)}>Add</Button>
                </td>
              </tr>
            ))}
          </tbody>

        </table>
      </div>


    </>
  );
}

export default CommunityMember;
