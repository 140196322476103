import { useState, useEffect } from "react";
import { React, Suspense, useParams, useRef } from "react";
import Loading from "../UtilComponents/Loading";
import {
    getExercisesToEvaluate,
    acceptOrRejectStudentResponseExercise
}
    from "../../util/studentResponceUtil";
import { Await, useAsyncValue, useNavigate } from "react-router-dom";
import { Button, Stack, Table } from "react-bootstrap";
import { UserContext } from "../../context/userContext";
import { splitArray } from "../../util/generalutil";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function EvaluateExercise() {
    return (
        <Suspense fallback={<Loading show={"loading Exercises"} />}>
            <Await resolve={getExercisesToEvaluate().catch(console.log)}>
                <EvaluateExercises />
            </Await>
        </Suspense>
    );
}

function EvaluateExercises() {
    let exercises = useAsyncValue().data;
    const [exercisesToEvaluate, setExercisesToEvaluate] = useState(exercises);
    const [status, setStatus] = useState("accepted");
    const [remarks, setRemarks] = useState("");

    let navigate = useNavigate();

    const handlesubmit = (student_id, exercise_id) => {
        acceptOrRejectStudentResponseExercise(
            student_id,
            exercise_id,
            status,
            remarks).then(result => {
                console.log(result)
                setRemarks("");
                setStatus("");
                
                if (result.data != null) {
                    alert("status updated successfully");
                    getExercisesToEvaluate()
                        .then(response => {
                            console.log('successful:', response.data);
                            setExercisesToEvaluate(response.data);
                            setRemarks("");
                            setStatus("");
                        })
                        .catch(console.log);
                }
              
            });
    }

    return (
        <div className='exerciseContainer w-100 h-100'>
            <Stack direction='horizontal'
                gap={2}
                className='justify-content-evenly'>
            </Stack>
            <div>records found {exercisesToEvaluate.length}</div>
            <table className="table table-bordered w-100">
                <thead>
                    <tr>
                        <th className="text-center">Student Name</th>
                        <th className="text-center">Exercise Description</th>
                        <th className="text-center">Course Id</th>
                        <th className="text-center">Link</th>
                        <th className="text-center">Accept</th>
                        <th className="text-center">Reject</th>
                        <th className="text-center">Remarks</th>
                        <th className="text-center">Submit</th>
                    </tr>
                </thead>
                <tbody>
                    {exercisesToEvaluate
                        .sort((a, b) => b.happend_on - a.happend_on)
                        .map((student, stIndex) => (
                            <tr key={stIndex}>
                                <td className="text-center">{student.student_info[0].displayName}</td>
                                <td className="text-center">{student.exercise_info[0].description}</td>
                                <td className="text-center">{student.exercises.course_id}</td>
                                <td className="text-center">
                                    {student.exercises.solution_url && (
                                        <a href={student.exercises.solution_url} target="_blank" rel="noopener noreferrer">
                                            View Solution
                                        </a>
                                    )}
                                </td>
                                <td className="text-center">
                                    <input
                                        type="radio"
                                        name={`acceptReject_${stIndex}`}
                                        value="accepted"
                                        onChange={(e) => setStatus(e.target.value)}
                                    />
                                </td>
                                <td className="text-center">
                                    <input
                                        type="radio"
                                        name={`acceptReject_${stIndex}`}
                                        value="rejected"
                                        onChange={(e) => setStatus(e.target.value)}
                                    />
                                </td>
                                <td className="text-center">
                                    <input
                                        type="text"
                                        name={`remarks_${stIndex}`}
                                        
                                        onChange={(e) => setRemarks(e.target.value)}
                                    />
                                </td>
                                <td className="text-center">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => handlesubmit(student._id, student.exercises.exercise_id)}
                                    >
                                        Submit
                                    </button>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>

        </div >
    )
}
