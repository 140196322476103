import React, { useState, useEffect } from "react";
import axios from "axios";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import config from "../../src/json/config.json";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Form from 'react-bootstrap/Form';

const localizer = momentLocalizer(moment);

const CalendarAPP = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [events, setEvents] = useState([]);
  const [view, setView] = useState("month");
  const [date, setDate] = useState(new Date());
  const [showForm, setShowForm] = useState(false);
  const [community, setCommunity] = useState([]);
  const [communityID, setCommunityID] = useState("");
  const [studentid, setStudentid] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    getMemberCalenderCommunity(communityID, studentid)
  }, []);


  const getMemberCalenderCommunity = (communityID, studentid) => {
    const url = `${config.backEnd.hostURL}/admin/community/${communityID}/students/${studentid}/events`;

    axios
      .get(url, { headers: { "Content-Type": "application/json" } })
      .then((response) => {
        setEvents(response.data.data);

      })
      .catch((err) => {
        console.error("Error fetching community data:", err);
        setEvents([]);
      });

  }



  useEffect(() => {
    getCommunity();
  }, []);



  useEffect(() => {
    // Reset student selection
  }, [setStudentid]);

  const getCommunity = () => {
    const url = config.backEnd.hostURL + "/admin/community";

    axios
      .get(url, { headers: { "Content-Type": "application/json" } })
      .then((response) => {
        setCommunity(response.data.data);
        setCommunityID(response.data.data._id);
        setStudentid(response.data.data.memberId);
      })
      .catch((err) => {
        console.error("Error fetching community data:", err);
        setCommunity([]);
      });
  };

  const populatedata = () => {
    let json = {

      "title": title,
      "description": description,
      "date": date
    }
    return json;
  }


  const handleAddEvent = (communityID, studentid) => {

    const url = `${config.backEnd.hostURL}/admin/community/${communityID}/students/${studentid}/events`;

    let data = populatedata();
    console.log(data);
    axios
      .post(url, data, { headers: { "Content-Type": "application/json" } })
      .then((response) => {
        console.log(response.data.data);
        setShowForm(false)
        getMemberCalenderCommunity(communityID, studentid)

      })
      .catch((err) => {
        console.error("Error fetching community data:", err);

      });
  };


  const renderCalendar = () => {
    const daysInMonth = 31;
    const firstDayOfMonth = new Date(2024, 9, 1).getDay(); // October 2024 starts on a Tuesday (day 2)

    const rows = [];
    let cells = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      cells.push(<td key={`empty-${i}`} className="empty-cell"></td>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = `2024-10-${day.toString().padStart(2, '0')}`;

      // const dayEvents = events.filter(event => event.date === currentDate);
      const dayEvents = Array.isArray(events) ? events.filter(event => event.date === currentDate) : [];

      cells.push(
        <td key={day}>
          <div className="calendar-cell">
            <strong>{day}</strong>
            {dayEvents.length > 0 && (
              <div className="event-list">
                {dayEvents.map(event => (
                  <div key={event._id}>
                    <strong>{event.title}</strong>
                    <p>{event.description}</p>
                    {/* <button onClick={() => handleEditEvent(event)} className="edit-button">Edit</button> */}
                  </div>
                ))}
              </div>
            )}
          </div>
        </td>
      );

      if ((day + firstDayOfMonth) % 7 === 0 || day === daysInMonth) {
        rows.push(<tr key={`week-${day}`}>{cells}</tr>);
        cells = [];
      }
    }

    return rows;
  };


  return (
    <div style={{ display: "flex", height: "90vh", padding: "20px" }}>
      <div style={{ flex: 1, padding: "20px" }}>
        <button
          onClick={() => setShowForm(true)}
          style={{
            background: "#4CAF50",
            color: "white",
            padding: "10px 15px",
            border: "none",
            cursor: "pointer",
            fontSize: "16px",
            marginBottom: "15px",
            borderRadius: "5px",
          }}
        >
          ➕ Add Event
        </button>

        {showForm && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              background: "white",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              zIndex: 1000,
              width: "300px",
            }}
          >
            <h3>Add Event</h3>
            <form style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Form.Select
                value={communityID}
                onChange={(e) => setCommunityID(e.target.value)}
                aria-label="Select Community"
              >
                <option value="">Select Community</option>
                {community.map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.name} {/* Show name instead of ID */}
                  </option>
                ))}
              </Form.Select>

              {/* Show members when a community is selected */}
              {communityID && (
                <Form.Select
                  value={studentid}
                  onChange={(e) => setStudentid(e.target.value)}
                  aria-label="Select Member"
                >
                  <option value="">Select Member</option>
                  {community.find((c) => c._id === communityID)?.members?.map((member) => (
                    <option key={member.memberId} value={member.memberId}>
                      {member.name} {/* Display member name */}
                    </option>
                  ))}
                </Form.Select>
              )}

              <label>Events:</label>

              <label>Title:</label>
              <input type="text" name="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
              <label>Description:</label>
              <textarea name="description" value={description} onChange={(e) => setDescription(e.target.value)} />

              <label>Date:</label>
              <input
                type="datetime-local"
                value={moment(date).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) => setDate(e.target.value)}
                required
              />

              <button type="button" onClick={() => handleAddEvent(communityID, studentid)}>Save</button>
              <button type="button" onClick={() => setShowForm(false)}>Cancel</button>
            </form>
          </div>
        )}




        <table className="calendar-table">
          <thead>
            <tr>
              <th>Sunday</th>
              <th>Monday</th>
              <th>Tuesday</th>
              <th>Wednesday</th>
              <th>Thursday</th>
              <th>Friday</th>
              <th>Saturday</th>
            </tr>
          </thead>
          <tbody>
            {renderCalendar()}
            {/* {events.map((item, index) => (
              <tr key={index}>
                <td>{item.Title}</td>
                <td>{item.Description}</td>
                <td>{item.Date}</td>
              </tr>
            ))} */}
          </tbody>
        </table>

        {/* <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          selectable
          onSelectSlot={handleSelectSlot}
          views={["month", "week", "day", "agenda"]}
          view={view}
          onView={(newView) => setView(newView)}
          date={date}
          onNavigate={(newDate) => setDate(newDate)}
          style={{ height: "500px", margin: "0 auto" }}
        /> */}
      </div>
    </div>
  );
};

export default CalendarAPP;
