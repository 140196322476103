import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation} from "react-router-dom";
import { Button, Form, Container } from "react-bootstrap";
import { faCheckCircle, faEye, faEyeSlash, faSignIn, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { tryToLogin } from "../../util/loginutil";
import '../../css/Login.css';
import '../../css/glassmorphic.css';
import axios from "axios";
import config from '../../json/config.json';
function ShowPassword(props) {
    return (
        <Button className="showPassword "
            onClick={() => props.controlShow(!props.show)}>

            <FontAwesomeIcon icon={props.show ? faEyeSlash : faEye} />

        </Button>
    )
}

function Login(props) {
    let navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [isEmailValid, setIsEmailValid] = useState(null);
    const [isPasswordValid, setIsPasswordValid] = useState(null);
    const [isShowing, setIsShowing] = useState(false);

    const sendDataToBackend = async () => {
        try {
            const currentDate = new Date(); 
            const data = {
                email: email,
                path: location.pathname,
                date: currentDate.toLocaleDateString(),
                time: currentDate.toLocaleTimeString()
            };

            await axios.post(config.backEnd.hostURL + '/Tracking', data); // Send data to backend endpoint
            console.log('Data saved successfully');
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    function validateForm() {
        return isEmailValid && isPasswordValid;
    }

    function handleSubmit(e) {
        let isUserFound = false;
        e.preventDefault();
        if (validateForm()) {
            tryToLogin(
                { 'email': email, 'password': password },
                console.log
            ).then(result => {
                if (result.data == 'request error-args not given') {
                    alert('email and password not sent');
                }
                else if (result.data == "not found") {
                    alert('Invalid Credentials')
                }
                else {
                    props.setUserCookie('user_token', result.data._id);
                    props.setUser(result.data);
                    sendDataToBackend(); 

                }
            }).catch(console.error)
        }
        else
            return false;
    }

    useEffect(() => {
        if (email == null)
            setIsEmailValid(null);
        else {
            let emailValidity = ((email != "") &&
                (email.indexOf('@') != 0) &&
                (email.indexOf('@') != -1) &&
                (email.indexOf('@') != (email.length - 1)) &&
                (email.indexOf(' ') == -1) &&
                (email.indexOf('@') == email.lastIndexOf('@')));

            setIsEmailValid(emailValidity)
        }
    }, [email]);

    useEffect(() => {
        if (password == null) {
            setIsPasswordValid(null);
        }
        else {
            let passwordValidity = (password != "") && (password.length > 8);

            setIsPasswordValid(passwordValidity)
        }
    }, [password]);

    function ShowSymbol(props) {

        if (props.condition == true) {
            return <FontAwesomeIcon icon={faCheckCircle} size={"2x"} className="validationIcon correct" />
        }
        else if (props.condition == false) {
            return <FontAwesomeIcon icon={faTimesCircle} size={"2x"} className="validationIcon inCorrect" />
        }

    }

    if (props.user) {
        console.log(props.user.role);
        if (props.user.role == "visitor")
            navigate("/home");
        if (props.user.role == "student")
            navigate("/dashboard");
        if (props.user.role == "tutor")
            navigate("/tutordashboard");
        if (props.user.role == "staff")
            return (<>Welcome {props.user.displayName}</>)
    }
    else
        return (
    <>
            <Container className="content">
                <Form onSubmit={handleSubmit}>
                    <div className="h1 pt-5 pb-3 text-center">
                        Login
                    </div>
                    <div className="pb-3">
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            autoComplete="email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div>
                        <Form.Control
                            autoComplete="current-password"
                            type={isShowing ? "text" : "password"}
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>

                    {/* <Col xs={2} className="d-flex align-items-center">
                            <ShowPassword show={isShowing} controlShow={setIsShowing} />
                        </Col> */}

                    <div className="pt-3 pb-3 text-center">
                        <Button variant="primary" type="submit" disabled={!validateForm()}>
                            Login
                            <FontAwesomeIcon icon={faSignIn} className="ms-2" />
                        </Button>
                    </div>
                    {/* <div class="register-link">
                        <a href="#">Forgot password?</a>
                    </div> */}
                    <div class="register">
                        <p>Don't have an account? <Link to="/signup">Register</Link></p>
                    </div>
                </Form>
            </Container>
            </>
        )
}

export default Login;