import React, { useContext, useState } from 'react';
import { Container, Stack, Row, Col, Button } from 'react-bootstrap';
import { UserContext } from '../context/userContext';
import { Link, useNavigate } from 'react-router-dom';
import './chatbot.css';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { useParams } from 'react-router-dom';

function Dashboard(props) {
    let navigate = useNavigate();
    const user = useContext(UserContext);
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');

    const [communities, setCommunities] = useState({
        Python: true,
        "Machine Learning": "",
        "Web Development": "",
        "Data Science": ""
    });

    const toggleCommunity = (community) => {
        setCommunities((prev) => ({
            ...prev,
            [community]: !prev[community],
        }));
    };
 

    const gotocalendar = () => navigate('/CalendarAPP');


    const handleSend = async () => {
        if (input.trim() === '') return;
        setMessages([...messages, { text: input, type: 'user' }]);
        setInput('');

        try {
            const response = await axios.post('http://127.0.0.1:5000/ask', { question: input });
            setMessages([...messages, { text: response.data.answer, type: 'bot' }]);
        } catch (error) {
            setMessages([...messages, { text: 'Error: ' + error.message, type: 'bot' }]);
        }
    };

    if (user == null) {
        return <Link className="btn btn-primary" to="/login">Login to continue</Link>;
    } else {
        return (
            <div style={{ display: "flex", height: "100vh" }}>
                {/* Main Content */}
                <div style={{ flex: 1, padding: "20px" }}>
                    <Stack>
                        <Row>
                            <Col>
                                <Container className="w-100 mb-2">
                                    <h2>Exercises</h2>
                                    <p><center>Hands-on examples of learning concepts</center></p>
                                    <Link to="/ex2">
                                        <Button variant="primary" className='w-75 b-2'>Get Started</Button>
                                    </Link>
                                </Container>
                            </Col>
                            <Col>
                                <Container className="w-100 mb-2">
                                    <h2>FAQ's</h2>
                                    <p><center>Quick clarification on key concepts</center></p>
                                    <Link to="/faq">
                                        <Button variant="primary" className='w-75 b-2'>Get Started</Button>
                                    </Link>
                                </Container>
                            </Col>
                            <Col>
                                <Container className="w-100 mb-2">
                                    <h2>Notes</h2>
                                    <p><center>Concise summaries highlighting key points</center></p>
                                    <Link to="/">
                                        <Button variant="primary" className='w-75 b-2'>Get Started</Button>
                                    </Link>
                                </Container>
                            </Col>
                        </Row>
                    </Stack>

                    <div className='todaysplan'>
                        {/* <h2 style={{ paddingLeft: "5%" }}>Good Morning</h2> */}
                        <h2 style={{ paddingLeft: "5%" }}>Good day to you!</h2>
                       
                        <p style={{ paddingLeft: "5%" }}>No lessons today. Plan ahead?&nbsp;
                            <FontAwesomeIcon icon={faBook} style={{ color: "red" }} />
                        </p>

                        {/* <div className='scheduleprepare'>
                            <h2>Plan ahead</h2>
                            <p>Check your schedule to prepare for your next lessons.</p>
                            <Button variant="primary" style={{ height: '40px', width: "20%" }} onClick={gotocalendar}>
                                View Schedule
                            </Button>
                        </div> */}
                    </div>

                    {/* Chatbot Section */}
                    <div className="chatbot">
                        <div className="chat-window">
                            {messages.map((msg, index) => (
                                <div key={index} className={`message ${msg.type}`}>
                                    {msg.text}
                                </div>
                            ))}
                        </div>

                        <div className="chat-input">
                            <input
                                type="text"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                placeholder="How can I help you..."
                            />
                            <button onClick={handleSend}>Send</button>
                        </div>
                    </div>
                </div>
{/* 
                <div style={{ width: "250px", padding: "20px" , marginRight:"5%"}}>
                    <h3 style={{ textAlign: "center" }}>📌 Communities</h3>
                    {Object.entries(communities).map(([community, isMember]) => (
                        <div
                            key={community}
                            style={{
                                border: "2px solid black",
                                padding: "15px",
                                marginBottom: "10px",
                                textAlign: "center",
                                borderRadius: "5px",
                                background: "white",
                            }}
                        >
                            <strong>{community}</strong>
                            <button
                                onClick={() => toggleCommunity(community)}
                                style={{
                                    display: "block",
                                    width: "100%",
                                    marginTop: "10px",
                                    padding: "8px",
                                    background: isMember ? "green" : "blue",
                                    border: "none",
                                    cursor: "pointer",
                                    borderRadius: "3px",
                                    color: "white",
                                    fontWeight: "bold",
                                }}
                              
                            >
                                {isMember ? "Member" : "+ Join Community"}
                            </button>
                        </div>
                    ))}
                </div> */}

            </div>
        );
    }
}

export default Dashboard;
