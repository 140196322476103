import axios from 'axios';
import config from '../json/config.json';
// import { validate } from 'schema-utils';
import { validate } from './generalutil';
import { _url } from './middleWareUtil';
function validateLoginCred(data, callback) {
    let schema = {
        email: { type: "string", format: "email" },
        password: { type: "string" },
    };
    if (data.token)
        return true;
    return validate(data, schema, callback);
}

export async function tryToLogin(data, callback = () => { }) {


    if (validateLoginCred(data, callback)) {

        var url = config.backEnd.hostURL+"/login";

        return (
            
            await axios.post(url, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        )
    } else
        throw "invalid credentials";
}

