import { global_user as user } from "../components/PortalApp";
import { makePath } from "./generalutil";
import { axiosWAuth } from "./axiosWAuth";
import { _url, adminOnly, noAuth } from "./middleWareUtil";
export async function getFAQById(faq_id) {
	if (user) {
		if (user.role == "staff") {
			let _practiceFAQURL = _url.admin.faq.getById + "/" + faq_id;
			return await axiosWAuth.get(_practiceFAQURL);
		} else if (user.role == "student") {
			let _practiceFAQURL = makePath([_url.nonAdmin.faq.getById, user._id, faq_id]);
			return await axiosWAuth.get(_practiceFAQURL);
		}
	} else noAuth();
}
//course_id,chapter_id,topic_id,complexity_id,description,hint,options,answerIndex
window.getFAQ = getFAQ;
export async function getFAQ(course_id, chapter_id = null, topic_id = null, complexity_id = null) {
	if (user) {
		if (user.role == "staff") {
			let _practiceFAQURL = makePath([
				_url.admin.faq.view,
				course_id,
				chapter_id,
				topic_id,
				complexity_id,
			]);
            console.log(_practiceFAQURL)
			return await axiosWAuth.get(_practiceFAQURL);
		} else if (user.role == "student") {
			let _practiceFAQURL = makePath([
				_url.nonAdmin.faq.get,
				user._id,
				course_id,
				chapter_id,
				topic_id,
				complexity_id,
			]);
			return await axiosWAuth.get(_practiceFAQURL);
		}
	} else noAuth();
}

function validate_faq_obj(faq_obj) {
	if (
		faq_obj.description == "" ||
		faq_obj.options == null ||
		faq_obj.options.length < 2 ||
		faq_obj.answerIndex == null ||
		faq_obj.answerIndex < 0 ||
		faq_obj.answerIndex >= faq_obj.options.length ||
		isNaN(Number(faq_obj.answerIndex))
	)
		return false;

	return true;
}

export async function addFAQ(course_id, chapter_id, topic_id, faq_obj) {
	if (adminOnly(user)) {
		if (validate_faq_obj(faq_obj)) {
			let body = {
				course_id: course_id,
				chapter_id: chapter_id,
				topic_id: topic_id,
				faq: faq_obj,
			};
			return await axiosWAuth.post(_url.admin.faq.add, body, {
				headers: {
					"Content-Type": "application/json",
				},
			});
		} else {
			throw "invalid faq_obj";
		}
	}
}
export async function replaceFAQ(faq_id, course_id, chapter_id, topic_id, faq_obj) {
	if (adminOnly(user)) {
		if (validate_faq_obj(faq_obj)) {
			let body = {
				faq_id: faq_id,
				course_id: course_id,
				chapter_id: chapter_id,
				topic_id: topic_id,
				faq: faq_obj,
			};
			return await axiosWAuth.post(_url.admin.faq.replace, body, {
				headers: {
					"Content-Type": "application/json",
				},
			});
		} else {
			throw "invalid faq_obj";
		}
	}
}
export async function deleteFAQ(faq_id) {
	//todo make it delete instead of get
	if (adminOnly(user)) {
		return await axiosWAuth.get(_url.admin.faq.delete + "/" + faq_id);
	} else noAuth();
}
export async function getExerciseById(exercise_id) {
	if (user) {
		if (user.role == "staff") {
			return await axiosWAuth.get(_url.admin.exercises.getById + "/" + exercise_id);
		} else if (user.role == "student") {
			let result = await axiosWAuth.get(
				makePath([_url.nonAdmin.exercises.getById, user._id, exercise_id])
			);
			if (typeof result.data == "string") throw result.data;

			return result;
		}
	} else noAuth();
}
export async function getExercises(course_id, chapter_id, topic_id, _complexity_id = null) {
	if (user) {
		if (user.role == "staff") {
			let _practiceExercisesURL = makePath([
				"/admin/exercises/path",
				course_id,
				chapter_id,
				topic_id,
			]);
			console.log("getting " + _practiceExercisesURL + "as admin");
			return await axiosWAuth.get(_practiceExercisesURL);
		}
		if (user.role == "student") {
			let _practiceExercisesURL = makePath([
				'/exercises',
				user._id,
				course_id,
				chapter_id,
				topic_id,
			]);
			console.log("getting " + _practiceExercisesURL);
			return await axiosWAuth.get(_practiceExercisesURL);
		}
	} else noAuth();
}
function validate_exercise_obj(exercise_obj) {
	exercise_obj.complexity_id = Number(exercise_obj.complexity_id);
	if (
		exercise_obj.description == "" ||
		exercise_obj.complexity_id == null ||
		exercise_obj.complexity_id < 0 ||
		exercise_obj.complexity_id >= 5 ||
		isNaN(exercise_obj.complexity_id)
	)
		return false;
	return true;
}

export async function addExercise(course_id, chapter_id, topic_id, exercise_obj) {
	if (adminOnly(user)) {
		let temp_exercise = { ...exercise_obj };

		if (validate_exercise_obj(temp_exercise)) {
			let body = {
				course_id: course_id,
				chapter_id: chapter_id,
				topic_id: topic_id,
				exercise: temp_exercise,
			};
			return await axiosWAuth.post(_url.admin.exercises.add, body, {
				headers: {
					"Content-Type": "application/json",
				},
			});
		} else {
			throw "invalid exercise_obj";
		}
	}
}

export async function replaceExercise(exercise_id, course_id, chapter_id, topic_id, exercise_obj) {
	if (adminOnly(user)) {
		let temp_exercise = { ...exercise_obj };
		if (validate_exercise_obj(temp_exercise)) {
			let body = {
				exercise_id: exercise_id,
				course_id: course_id,
				chapter_id: chapter_id,
				topic_id: topic_id,
				exercise: temp_exercise,
			};
			return await axiosWAuth.post(_url.admin.exercises.replace, body, {
				headers: {
					"Content-Type": "application/json",
				},
			});
		} else {
			throw "invalid exercise_obj";
		}
	}
}

export async function deleteExercise(exercise_id) {
	///todo make it delete
	if (adminOnly(user)) {
		return await axiosWAuth.get(_url.admin.exercises.delete + "/" + exercise_id);
	}
}
