import React, { useEffect, useState, useCallback } from 'react';
import { Form, Row, Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAllCourses } from '../../util/courseutil';
import Loading from '../UtilComponents/Loading';
import SelectCourseHierarchy2 from './SelectCourseHierarchy2';
import PracticeExercises2 from './PracticeExercises2';

function SelectExercises2() {
    const navigate = useNavigate();

    // State variables
    const [courseId, setCourseId] = useState(null);
    const [chapterId, setChapterId] = useState(null);
    const [topicId, setTopicId] = useState(null);
    const [courseList, setCourseList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCourses = async () => {
            setLoading(true);
            try {
                const resp = await getAllCourses();
                const courses = resp.data || []; // Ensure it's an array
                setCourseList(courses);

                // Dynamically setting course and chapter IDs
                if (courses.length > 0) {
                    const firstCourse = courses[0];

                    setCourseId(prevId => prevId || firstCourse.course_id || null);
                    setChapterId(prevId => prevId || firstCourse.chapters?.[0]?.chapter_id || null);
                }
            } catch (error) {
                console.error("Error fetching courses:", error);
                setError("Failed to fetch courses. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchCourses();
    }, []); // Runs only on mount

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        if (courseId) {
            navigateExercises(courseId, chapterId, topicId);
        }
    }, [courseId, chapterId, topicId]);

    const navigateExercises = useCallback((course_id, chapter_id, topic_id) => {
        console.log(`Navigating to exercises: Course ${course_id}, Chapter ${chapter_id}, Topic ${topic_id}`);
        // Navigation logic (if required)
    }, []);

    return (
        <Form onSubmit={handleSubmit} className="card p-3 gap-3">
            <Container fluid>
                {loading ? (
                    <Row>
                        <Col md={12}>
                            <Loading />
                        </Col>
                    </Row>
                ) : error ? (
                    <Row>
                        <Col md={12} className="text-danger">
                            {error}
                        </Col>
                    </Row>
                ) : (
                    <>
                        <Row>
                            <Col md={12}>
                                <SelectCourseHierarchy2
                                    courseList={courseList}
                                    courseId={courseId}
                                    setCourseId={setCourseId}
                                    chapterId={chapterId}
                                    setChapterId={setChapterId}
                                    topicId={topicId}
                                    setTopicId={setTopicId}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                {courseId && (
                                    <PracticeExercises2
                                        courseId={courseId}
                                        chapterId={chapterId}
                                        topicId={topicId}
                                    />
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </Container>
        </Form>
    );
}

export default SelectExercises2;
