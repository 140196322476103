import React, { useEffect, useState } from "react";
import { getCourseStruct } from "../../util/courseutil";
import { Container, FormSelect, Row, Col } from "react-bootstrap";
import { strNotNull } from "../../util/generalutil";
import "../../css/SelectCourseHierarchy.css";

export default function SelectCourseHierarchy2(props) {
	const { courseId, invokeLoadCourseRef, setInvokeLoadCourseRef, setChapterId, setTopicId, setCourseId, courseList, disabled = false, autoFocus } = props;
	const [courseRef, setCourseRef] = useState(null);

	// Load course structure when courseId or invokeLoadCourseRef changes
	useEffect(() => {
		if (courseId || invokeLoadCourseRef) {
			loadCourseRef(courseId);
			if (invokeLoadCourseRef) setInvokeLoadCourseRef(false);
		}
	}, [courseId, invokeLoadCourseRef]);

	async function loadCourseRef(_course_id) {
		try {
			if (!_course_id) return;

			console.log(`Fetching course structure for: ${_course_id}`);
			const result = await getCourseStruct(_course_id);
			console.log(result.data)
			if (!result?.data) {
				console.warn("Course structure is empty or undefined.");
				setCourseRef(null);
				setChapterId(null);
				setTopicId(null);
				return;
			}

			console.log("Course structure:", result.data);
			setCourseRef(result.data);

			const firstChapter = result.data.chapters?.[0] || null;
			setChapterId(firstChapter?.chapter_id || null);

			const firstTopic = firstChapter?.topics?.[0] || null;
			setTopicId(firstTopic?.topic_id || null);
		} catch (err) {
			console.error("Error loading course structure:", err);
			setCourseRef(null);
			setChapterId(null);
			setTopicId(null);
		}
	}

	const handleCourseChange = (e) => {
		const selectedCourse = e.target.value === "select" ? null : e.target.value;
		setCourseId(selectedCourse);
		setCourseRef(null);
		setChapterId(null);
		setTopicId(null);
	};

	const handleChapterChange = (e) => {
		const selectedChapter = e.target.value === "select" ? null : e.target.value;
		setChapterId(selectedChapter);
		setTopicId(null);
	};

	const handleTopicChange = (e) => {
		const selectedTopic = e.target.value === "select" ? null : e.target.value;
		setTopicId(selectedTopic);
	};

	return (
		<Container fluid>
			<Row>
				{/* Course Selection */}
				<Col sm={12} lg={4}>
					<FormSelect className="mb-0" autoFocus={autoFocus} disabled={disabled} onChange={handleCourseChange} value={strNotNull(courseId)}>
						<option value="select">Choose course</option>
						{courseList?.map((course, index) => (
							<option key={index} value={strNotNull(course.course_id)}>
								{course.course_name}
							</option>
						))}
					</FormSelect>
				</Col>

				{/* Chapter Selection */}
				<Col sm={12} lg={4}>
					<FormSelect className="mb-0" disabled={disabled} onChange={handleChapterChange} value={strNotNull(props.chapterId)}>
						<option value="select">Choose chapter</option>
						{courseRef?.chapters?.map((chapter, index) => (
							<option key={index} value={strNotNull(chapter.chapter_id)}>
								{chapter.chapter_name}
							</option>
						))}
					</FormSelect>
				</Col>
				{/* <Col sm={12} lg={4}>
				<FormSelect className="mb-0" disabled={disabled} onChange={handleChapterChange} value={strNotNull(props.chapterId)}>
					<option value="select">Choose chapter</option>
					{courseRef?.chapters
						?.filter((chapter) => chapter.topics && chapter.topics.length > 0) // Only include chapters that have topics
						.map((chapter, index) => (
							<option key={index} value={strNotNull(chapter.chapter_id)}>
								{chapter.chapter_name}
							</option>
						))}
				</FormSelect>
				</Col> */}

				{/* Topic Selection */}
				<Col sm={12} lg={4}>
					<FormSelect disabled={disabled} onChange={handleTopicChange} value={strNotNull(props.topicId)}>
						<option value="select">Choose topic</option>
						{courseRef?.chapters?.find((chapter) => chapter.chapter_id === props.chapterId)?.topics?.map((topic, index) => (
							<option key={index} value={strNotNull(topic.topic_id)}>
								{topic.topic_name}
							</option>
						))}
					</FormSelect>
				</Col>
			</Row>
		</Container>
	);
}