import {
	faAngleRight, faGauge,
	faInfoCircle, faSquareArrowUpRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import { Alert, Button, Container, Form, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../../css/PracticeExercise.css";
import { makePath } from "../../util/generalutil";
import { getExerciseById, getExercises } from "../../util/questionutil";
import { getComplexities } from '../../util/cplxutil';
import { global_user } from "../PortalApp";
import { axiosWAuth } from "../../util/axiosWAuth";
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';

function QuestionModule2(props) {
	const location = useLocation();
	const [text, setText] = useState("");
	const [dot, setDot] = useState(true);

	const exComplexity = props.complexityDict?.find(
		(item) => item.complexity_id === props.exercise?.complexity_id
	) || {};

	console.log(exComplexity);

	useEffect(() => {
		const currentDate = new Date(); // Get current date and time
		console.log("Current URL path:", location.pathname);
		console.log("Current date and time:", currentDate.toLocaleString());
	}, [location]);

	// function handleSubmit(e) {
	// 	e.preventDefault();
	// 	if (validateSubmit()) {
	// 		const formData = new FormData();

	// 		// Get the exercise ID from props.exercise
	// 		const exerciseId = props.exercise._id;
	// 		const courseId = props.exercise.course_id;
	// 		const chapterId = props.exercise.chapter_id;
	// 		const topicId = props.exercise.topic_id;
	// 		const studentId = global_user._id;
	// 		// Get the current time
	// 		const submissionTime = new Date().toISOString();

	// 		// Include exercise ID and submission time in the form data
	// 		formData.append("file", file);
	// 		formData.append("studentId", studentId);
	// 		formData.append("courseId", courseId);
	// 		formData.append("chapterId", chapterId);
	// 		formData.append("topicId", topicId);
	// 		formData.append("exerciseId", exerciseId);
	// 		formData.append("timestamp", Date.now());
	// 		formData.append("submissionTime", submissionTime);

	// 		axios
	// 			.post(config.backEnd.hostURL + "/upload", formData, {
	// 				headers: {
	// 					"Content-Type": "multipart/form-data",
	// 					"studentid": global_user._id
	// 				},
	// 			})
	// 			.then((response) => {
	// 				console.log("File upload success:", response.data);
	// 				window.alert("Submitted");
	// 				axiosWAuth
	// 					.post("/student/response", {
	// 						student_id: studentId,
	// 						exercise_id: exerciseId,
	// 						course_id: courseId,
	// 						chapter_id: chapterId,
	// 						topic_id: topicId,
	// 						status: "submitted",
	// 						attachment_file_path: file.name,
	// 						timestamp: Date.now(),
	// 						action: "submitted",
	// 						actionDate: Date.now(),
	// 						actionBy: "student",
	// 						submissionTime,
	// 					})
	// 					.then((studentResponse) => {
	// 						console.log("Exercise submission success:", studentResponse.data);
	// 						props.setArrayEx(prevArray => prevArray.filter(item => item._id != exerciseId));
	// 					})
	// 					.catch((studentError) => {
	// 						console.error("Exercise submission error:", studentError);
	// 					});
	// 			})
	// 			.catch((error) => {
	// 				console.error("File upload error:", error);
	// 			});
	// 	} else {
	// 		return false;
	// 	}
	// }


	function handleSubmit(e) {
		e.preventDefault();
		if (validateSubmit()) {
			const formData = {
				solutionUrl: text,
				studentId: global_user._id,
				courseId: props.exercise.course_id,
				chapterId: props.exercise.chapter_id,
				topicId: props.exercise.topic_id,
				exerciseId: props.exercise._id,
				timestamp: Date.now(),
				submissionTime: new Date().toISOString()
			};

			axiosWAuth
				.post("/student/response", {
					student_id: formData.studentId,
					exercise_id: formData.exerciseId,
					course_id: formData.courseId,
					chapter_id: formData.chapterId,
					topic_id: formData.topicId,
					status: "submitted",
					attachment_file_path: null,
					solution_url: formData.solutionUrl,
					timestamp: formData.timestamp,
					action: "submitted",
					actionDate: formData.timestamp,
					actionBy: "student",
					submissionTime: formData.submissionTime,
				})
				.then((studentResponse) => {
					console.log("Exercise submission success:", studentResponse.data);
					window.alert("Submitted");
					setText("");

					// Remove submitted exercise
					props.setArrayEx(prevArray => prevArray.filter(item => item._id !== formData.exerciseId));

					// **Trigger refresh of exercises**
					if (props.refreshExercises) {
						props.refreshExercises();
					}
				})
				.catch((studentError) => {
					console.error("Exercise submission error:", studentError);
				});
		} else {
			return false;
		}
	}

	function validateSubmit() {
		return text.trim().length > 0; // Enable only if text is entered
	}

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
		const handleClick = (event) => {
			event.preventDefault();
			onClick(event);
		};

		return (
			<div ref={ref} onClick={handleClick} style={{ cursor: 'pointer' }}>
				{children}
			</div>
		);
	});

	return (
		<Container fluid>
			<Row>
				<Col md={12}>
					<Form
						className={`vstack m-auto gap-2 maxw600 view-${props.view} hover-box`}
						disabled={!validateSubmit()}
						style={{ border: '1px solid grey', borderRadius: '20px', padding: '8px' }}
					>
						<Row>
							<Col md={11}>{props.exercise.description}</Col>

							<Col md={1} className="d-flex justify-content-end position-relative">
								{dot &&
									<Dropdown>
										<Dropdown.Toggle as={CustomToggle} className="d-flex align-items-center">
											<FontAwesomeIcon icon={faEllipsisH} />
										</Dropdown.Toggle>
										<Dropdown.Menu className="dotdot">
											<Dropdown.Item>

												<Col md={2} className="hintCont mt-0" style={{ paddingLeft: "25px" }} >

													{props.exercise.meta?.notes?.stu_status == "complete"
														? "Got stuck?Re-Refer "
														: ""}
													<Link target="_blank"
														to={makePath([
															"/notes/sub",
															props.exercise.course_id,
															props.exercise.chapter_id,
															props.exercise.topic_id,

														])}
													>
														notes <FontAwesomeIcon icon={faSquareArrowUpRight} />
													</Link>
												</Col>


											</Dropdown.Item>

											<Dropdown.Item>
												<Col md={2} className="mt-0">
													<FontAwesomeIcon icon={faGauge} className="me-1" />
													{exComplexity.complexity_name}

												</Col>
											</Dropdown.Item>
											<Dropdown.Item>
												<Col className="bg-danger">
													<p className="status badge bg-light text-dark align-self-end">
														{props.exercise.stu_response_status == "not found in student response"
															? "not attempted"
															: props.exercise.stu_response_status}
													</p>
												</Col>

											</Dropdown.Item>

											<Dropdown.Item>
												<div className="content-wrapper">
													<Col md={6}>
														<Col className="text-muted user-select-none assist"></Col>
														{props.exercise.hint && (
															<Alert variant="info" className="p-1 m-0 hintCont">
																<FontAwesomeIcon icon={faInfoCircle} className="me-2 ms-2" />
																{props.exercise.hint}
															</Alert>
														)}
													</Col>
												</div>
											</Dropdown.Item>


										</Dropdown.Menu>
									</Dropdown>
								}
							</Col>
						</Row>
						<Row>

							<Col md={6}>
								<p
									className="status badge align-self-end"
									style={{
										color:
											props.exercise.stu_response_status === "rejected"
												? "red"
												: props.exercise.stu_response_status === "accepted"
													? "green"
													: "black",
									}}
								>
									{props.exercise.stu_response_status === "not found in student response"
										? "not attempted"
										: props.exercise.stu_response_status}
								</p>
							</Col>
							<Col md={6} className="hintCont">
								<Form.Group
									key={props.exercise._id}
									controlId={props.exercise._id + "file"}
									className="fileHolder"
								>
									{/* {file && file.name}
									<Form.Label className="btn btn-primary mt-0 mb-0">
										{file ? "Change" : "Upload file"}
									</Form.Label> */}
									<input
										type="text"
										value={text}
										onChange={(e) => setText(e.target.value)}
										placeholder="Enter Reference here..."

									/>
									{/* <Button onClick={handleSubmit} className="hintContsubmit mt-1 mt-md-0 " disabled={!validateSubmit()}>
										Submit Solution
										<FontAwesomeIcon icon={faAngleRight} className=" ms-2" />
									</Button> */}
									<Button onClick={handleSubmit} className="hintContsubmit" disabled={!validateSubmit()}>
										Submit Solution
										<FontAwesomeIcon icon={faAngleRight} className="me-2 ms-2" />
									</Button>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</Col>
			</Row>
		</Container>
	);
}

function getExercisesByParams2(params) {
	if (params.exercise_id) return getExerciseById(params.exercise_id).catch(console.log);
	if (params.course_id) {
		if (params.chapter_id) {
			if (params.topic_id) {
				return getExercises(params.course_id, params.chapter_id, params.topic_id).catch(
					console.log
				);
			}
			return getExercises(params.course_id, params.chapter_id).catch(console.log);
		}
		return getExercises(params.course_id).catch(console.log);
	}
}

function PracticeExercises2(props) {
	const [exercises, setExercises] = useState([]);
	const [filtByResp, setFiltByResp] = useState([]);
	const [arrayEx, setArrayEx] = useState([]);
	const views = ["none", "full", "compact"];
	const [viewlvl, setViewlvl] = useState(0);
	const [complexityDict, setcomplexityDict] = useState([]);


	useEffect(() => {
		if (!props.courseId || !props.chapterId || !props.topicId) return;

		getComplexities().then(resp => {
			setcomplexityDict(resp.data);
		}).catch(error => {
			console.error(error); // Log error instead of using alert
		});

		const params = {
			course_id: props.courseId,
			chapter_id: props.chapterId,
			topic_id: props.topicId
		};

		getExercisesByParams2(params)
			.then(resp => {
				setExercises([resp.data].flat()); // Ensure it's always an array
				const fullExArray = [resp.data].flat();
				setFiltByResp(fullExArray)
				setArrayEx(fullExArray)
			})
			.catch(error => {
				console.error(error); // Log error instead of using alert
			});
	}, [props.courseId, props.chapterId, props.topicId]); // Include dependencies

	// Define the state for sorting option
	// const [sortOption, setSortOption] = useState('');

	// // Handle the dropdown selection
	// const handleSelect = (eventKey) => {
	// 	setSortOption(eventKey);  // Update the sort option based on the selection
	// };

	// const handleFilter = () =>{
	// 	const filter = arrayEx.filter(rec => rec.)

	// }
	// const [filteredEx, setFilteredEx] = useState(arrayEx);
	// const handleFilter = (filterType) => {
	// 	let filteredArray = [...arrayEx];

	// 	if (filterType === "Complexity Easy to First") {
	// 	  filteredArray.sort((a, b) => a.complexity - b.complexity);
	// 	} else if (filterType === "Complexity Difficult First") {
	// 	  filteredArray.sort((a, b) => b.complexity - a.complexity);
	// 	}

	// 	setFilteredEx(filteredArray);
	//   };

	const [sortOption, setSortOption] = useState(null);
	//const [filterOption, setFilterOption] = useState(NotAttempted);

	const handleSelect = (eventKey) => {
		setSortOption(eventKey);
		console.log("Selected Sort Option:", eventKey);
		// Handle sorting logic here
	};
	const [filterOption, setFilterOption] = useState("NotAttempted");

	// Apply default filtering when exercises change
	useEffect(() => {
		const filteredEx = exercises.filter(ex => ex.stu_response_status === null);
		setArrayEx([...filteredEx]);

		handleFilter(filterOption);

	}, [exercises]); // Re-run when exercises change


	const handleFilter = (eventKey) => {
		setFilterOption(eventKey);
		console.log("Selected Filter Option:", eventKey);

		let filteredEx = [];

		switch (eventKey) {
			case "NotAttempted":
				//filteredEx = exercises.filter(ex => ex.stu_response_status === null);
				filteredEx = exercises.filter(
					ex => ex.stu_response_status === null || ex.stu_response_status === "rejected"
				);
				break;
			case "Submitted":
				filteredEx = exercises.filter(ex => ex.stu_response_status === "submitted");
				break;
			case "Accepted":
				filteredEx = exercises.filter(ex => ex.stu_response_status === "accepted");
				break;
			case "Rejected":
				filteredEx = exercises.filter(ex => ex.stu_response_status === "rejected");
				// filteredEx = exercises
				break;
			case "All":
				filteredEx = exercises; // Show all exercises without filtering
				break;
			default:
				filteredEx = exercises;
		}


		setArrayEx([...filteredEx]);
	};



	return (
		<Container fluid>
			<Row className="" style={{ marginTop: "15px" }}>
				<Col md={2}>
					<DropdownButton
						variant="primary"
						id="dropdown-sort-by"
						title={sortOption ? sortOption : "Sort By"}
						onSelect={handleSelect}
					>
						<Dropdown.Item eventKey="Complexity Easy to First">
							Complexity Easy to First
						</Dropdown.Item>
						<Dropdown.Item eventKey="Complexity Difficult First">
							Complexity Difficult First
						</Dropdown.Item>
					</DropdownButton>
				</Col>
				<Col md={2}>

					<DropdownButton
						variant="primary"
						id="dropdown-filter-by"
						title={filterOption ? filterOption : "Filter By"}
						onSelect={handleFilter}
					>
						<Dropdown.Item eventKey="NotAttempted">Not attempted</Dropdown.Item>
						<Dropdown.Item eventKey="Submitted">Submitted</Dropdown.Item>
						<Dropdown.Item eventKey="Accepted">Accepted</Dropdown.Item>
						<Dropdown.Item eventKey="Rejected">Rejected</Dropdown.Item>
						<Dropdown.Item eventKey="All">All</Dropdown.Item>
					</DropdownButton>

				</Col>
			</Row>
			<Row>
				<Col>
					{arrayEx.length} exercises found
					{arrayEx.map((exercise, index) => (
						<div style={{ marginTop: '10px' }}>
							<Fragment key={index}>
								<QuestionModule2
									exercise={exercise}
									view={views[viewlvl]}

									setArrayEx={setArrayEx}
									complexityDict={complexityDict}
								/>

								{index == arrayEx.length - 1}
								{/* complexityDict={complexityDict} */}
							</Fragment>
						</div>
					))}
				</Col>
			</Row>
		</Container>
	);
}

export default PracticeExercises2;