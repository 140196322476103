import { useState, useEffect } from "react";
import axios from "axios";
import { Button, Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import config from '../../src/json/config.json'
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit , faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import Dropdown from 'react-bootstrap/Dropdown';
import React from 'react';


function Community() {
    const [community, setCommunity] = useState([]); // Initialize as an array

    const [name, setName] = useState("");
    const [code, setCode] = useState("");
    const [description, setDescription] = useState("");
    const [actionName, setActionName] = useState("Add")
    const [actionLabel, setActionLabel] = useState("Update")
    const [show, setShow] = useState(false);
    const [id, setId] = useState(0)

    useEffect(() => {
        getCommunity();
    }, []);


    const getCommunity = () => {

        const url = config.backEnd.hostURL + "/admin/community";
        axios
            .get(url, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log(response.data.data)
                setCommunity(response.data.data);
            })
            .catch((err) => {
                console.error("Error fetching community data:", err);
                setCommunity([]);
            });
    };

    const handleClose = () => {
        setShow(false);
        setActionName("New")
    }

    const handleShow = () => {
        setShow(true);
        setActionName("Add")
        setActionLabel("Add")
        resetCommunity()
    }

    const handleSave = (e) => {
        switch (actionName) {
            case "Add":
                addCommunity();
                break;
            case "Update":
                updateCommunity(id);
                break;
            default:
                break;
        }
    }

    const prepareCommunity = () => {
        let json = {
            "id": id,
            "name": name,
            "code": code,
            "description": description
        }
        return json;
    }


    const addCommunity = () => {
        //const newCommunity = { name, code, description };
        let data = prepareCommunity()
        console.log(data)
        const url = config.backEnd.hostURL + "/admin/community";

        axios
            .post(url, data, { headers: { "Content-Type": "application/json" } })
            .then((response) => {
                console.log("Saved successfully:", response.data);
                getCommunity(); // Refresh the list
                handleClose(); // Close the modal
            })
            .catch((error) => {
                console.error("Error saving community:", error);
            });
    };

    const updateCommunity = (id) => {
        let data = prepareCommunity()
        console.log(data)
        const url = config.backEnd.hostURL + "/admin/community/" + id;

        axios
            .put(url, data, { headers: { "Content-Type": "application/json" } })
            .then((response) => {
                console.log("Saved successfully:", response.data);
                getCommunity(); // Refresh the list
                handleClose(); // Close the modal
            })
            .catch((error) => {
                console.error("Error saving community:", error);
            });

    }

    const handleCommuntiyEdit = (community) => {
        setShow(true)
        setActionLabel("Save")
        setActionName("Update");
        // populatecommunity(community)
        if (community != null) {
            setId(community._id)
            populatecommunity(community);
        }
        // alert("Icon clicked!");
    }

    const populatecommunity = (community) => {
        setId(community._id)
        setName(community.name)
        setCode(community.code)
        setDescription(community.description)

    }

    const resetCommunity = () => {
        setName()
        setCode()
        setDescription()
    }


    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => {
        const handleClick = (event) => {
            event.preventDefault();
            onClick(event);
        };

        return (
            <div ref={ref} onClick={handleClick} style={{ cursor: 'pointer' }}>
                {children}
            </div>
        );
    });

let navigate = useNavigate();
const handlenavigate =(id) =>{
    navigate(`/CommunityMember/${id}`);
}

    return (
        <>
            <h1>Community</h1>
            <Row>
                <Col>
                    <Button onClick={handleShow}>Add</Button>
                </Col>
            </Row>
            <div className="data-table-wrapper">
            <table className="member-list-table">
                <thead>
                    <tr>
                        <th>#  </th>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Description</th>
                          <th>Actions</th>
                        <th> Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {community && community.length > 0 ? (
                        community.map((member, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{member.name}</td>
                                <td>{member.code}</td>
                                <td>{member.description}</td>
                                <td>
                                                <Dropdown align="end">
                                                    <Dropdown.Toggle as={CustomToggle}>
                                                        <FontAwesomeIcon icon={faEllipsisH} />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                    <Dropdown.Item href="#"><Link>
                                                    <FontAwesomeIcon icon={faEdit} onClick={() => handleCommuntiyEdit(member)} /></Link></Dropdown.Item>
                                                
                                                    <Dropdown.Item>Delete</Dropdown.Item>
                                                    <Dropdown.Item onClick={ ()=>handlenavigate(member._id)}>Community Member</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                    </Dropdown>
                                            </td>
                                            <td></td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="4">No community data found</td>
                        </tr>
                    )}
                </tbody>
      
           </table>

</div>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Community</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FloatingLabel controlId="floatingInput" label="Name" className="mb-3">
                        <Form.Control type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter name" />
                    </FloatingLabel>
                    <FloatingLabel label="Code" className="mb-3">
                        <Form.Control type="text"

                            value={code}
                            onChange={(e) => setCode(e.target.value)} placeholder="Enter code" />
                    </FloatingLabel>
                    <FloatingLabel label="Description">
                        <Form.Control type="text"

                            value={description}
                            onChange={(e) => setDescription(e.target.value)} placeholder="Enter description" />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave} >{actionLabel}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Community;
